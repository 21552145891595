body {
    margin: 0;
    background-color: #f4f4f4;
    color: #333;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.under-construction {
    flex: 1;
    text-align: center;

}

header {
    text-align: left;

}

.logo {
    height: auto;
    max-height: 150px;
    max-width: 100%;
    width: auto;
    margin-bottom: 2rem;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .logo {
        max-height: none;
        width: 100%;
        max-width: 90%;
    }
}

h1 {
    font-size: 2.5rem;
    color: #333;
    text-align: center;
}

p {
    font-size: 1.2rem;
    color: #666;
    text-align: center;
}

footer {
    background-color: #333;
    padding: 0.5rem;
    font-size: 0.9rem;
    color: #fff;
    text-align: center;
    width: 100%;
    margin-top: 20vh;
}

footer nav a {
    color: #0073e6;
    text-decoration: none;
    margin: 0 0.5rem;
}

footer nav a:hover {
    text-decoration: underline;
}

.social-icons {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.social-icons img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: transform 0.2s ease, opacity 0.2s ease;
    margin-top: 10px;
}

.social-icons img:hover {
    opacity: 0.8;
    transform: scale(1.1);
}
